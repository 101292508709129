addEventListener("trix-change", event => {
  const { editor } = event.target
  const string = editor.getDocument().toString()
  const characterCount = string.length - 1
  console.log(`The document has ${characterCount} characters`)
  const trixId = event.target.id + "_counter"
  console.log(`editor id: ${trixId}`)
  document.getElementById(trixId).innerHTML = characterCount;
})

document.addEventListener("trix-initialize", function(event) {
  console.log("Trix editor initialized");

  const trixEditor = event.target;
  const toolbar = trixEditor.toolbarElement;

  if (toolbar) {
    console.log("Toolbar found:", toolbar);

    const buttonGroup = toolbar.querySelector(".trix-button-group--text-tools");
    if (buttonGroup) {
      console.log("Button group found:", buttonGroup);

      const buttonHTML = `
        <button type="button" class="trix-button trix-button--icon trix-button--icon-nonbreaking-space" data-trix-action="insertNonBreakingSpace" title="Non-breaking space">
          <span style="text-indent: 0; white-space: normal; display: inline-block;">NBSP</span>
        </button>`;
      buttonGroup.insertAdjacentHTML("beforeend", buttonHTML);
      console.log("Non-breaking space button added");

      const style = document.createElement('style');
      style.innerHTML = `
        .trix-button--icon-nonbreaking-space {
          padding: 5px;
          font-size: 14px;
          background-color: #f0f0f0;
          border: 1px solid #ccc;
          border-radius: 3px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
          color: black !important;
          text-indent: 0 !important;
          white-space: normal !important;
          overflow: visible !important;
        }
        .trix-button--icon-nonbreaking-space:hover {
          background-color: #e0e0e0;
          border-color: #bbb;
        }`;
      document.head.appendChild(style);

      toolbar.addEventListener("click", function(event) {
        if (event.target.matches('[data-trix-action="insertNonBreakingSpace"]')) {
          console.log("Non-breaking space button clicked");
          trixEditor.editor.insertString(" ");
          console.log("Non-breaking space inserted");
        }
      });
    } else {
      console.error("Button group not found");
    }
  } else {
    console.error("Toolbar not found");
  }
});